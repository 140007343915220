import React from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Link,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import { Facebook, Twitter, Instagram, LinkedIn } from "@mui/icons-material";
import { footerInfo } from "../utils/info";

const Footer = () => {
  const FooterNavItems = [
    { title: "Home", id: "#home" },
    { title: "Services", id: "#services" },
    { title: "Our Locations", id: "#locations" },
    { title: "Contact Us", id: "#contactus" },
    { title: "Credits", id: "#credits" },
  ];

  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "#2C3335",
          color: "#fff",
          padding: "20px",
          fontFamily: "Roboto, sans-serif",
          display: "grid",
          gap: "20px",
          gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
          textAlign: isMobile ? "center" : "start",
          // marginX: isMobile ? "10px" : "20px",
        }}
      >
        {/* Navigation Box */}
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign: isMobile ? "center" : "start",
            }}
          >
            Navigate
          </Typography>
          {FooterNavItems.map((item) => (
            <Link
              key={item.id}
              href={item.id}
              color="inherit"
              sx={{
                display: "block",
                marginBottom: "5px",
                textDecoration: "none",
                textAlign: isMobile ? "center" : "start",
              }}
            >
              {item.title}
            </Link>
          ))}
        </Box>

        {/* Follow Us Box */}
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            Follow Us
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Link href={footerInfo.FOOTER_FB} color="inherit">
                <IconButton sx={{ color: "#fff" }}>
                  <Facebook />
                </IconButton>
              </Link>
            </Grid>
            <Grid item>
              <Link href={footerInfo.FOOTER_TWITTER} color="inherit">
                <IconButton sx={{ color: "#fff" }}>
                  <Twitter />
                </IconButton>
              </Link>
            </Grid>
            <Grid item>
              <Link href={footerInfo.FOOTER_INSTA} color="inherit">
                <IconButton sx={{ color: "#fff" }}>
                  <Instagram />
                </IconButton>
              </Link>
            </Grid>
            <Grid item>
              <Link href={footerInfo.FOOTER_LINKEDIN} color="inherit">
                <IconButton sx={{ color: "#fff" }}>
                  <LinkedIn />
                </IconButton>
              </Link>
            </Grid>
          </Grid>
          <Typography
            variant="body2"
            sx={{
              marginTop: "10px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            &copy; {new Date().getFullYear()} Your Company. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Footer;
