import React from "react";
import {
  Grid,
  Box,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";

import {
  VideoCameraFront,
  SmartDisplay,
  RecentActors,
} from "@mui/icons-material";

import ServicesBox from "../components/ServiceBox";

const boxItems = [
  {
    title: "Film & TV Production",
    description:
      "We produce feature films, short films, television series, and documentaries across various genres.Our team works from concept development to post-production to ensure every project exceeds industry standards.",
    detailedDescription: "",
    IconComponent: SmartDisplay,
  },
  {
    title: "Digital Media Content Creation",
    description:
      "We develop original digital content, including online series, webisodes, and interactive media, ensuring high engagement on all platforms.",
    detailedDescription: "",
    IconComponent: VideoCameraFront,
  },
  {
    title: "Talent Management",
    description:
      "Representing artists, actors, musicians, and performers, we provide career development, contract negotiations, and branding strategies.",
    detailedDescription: "",
    IconComponent: RecentActors,
  },
];

const theme = createTheme(); // Create a default theme

const OurServices = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      id="services"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="#dfe4ea"
      padding={2}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          color: "#2f3542",
          fontFamily: "Bebas Neue, sans-serif",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: isMobile ? "40px" : "60px",
          marginTop: "50px",
        }}
      >
        Our Services
      </Typography>
      <Typography
        variant="body1"
        align="center"
        gutterBottom
        marginLeft={isMobile ? "25px" : "100px"}
        marginRight={isMobile ? "25px" : "100px"}
        textAlign="justify"
      >
        We specialize in a wide range of entertainment services designed to
        cater to the modern consumer. The Blue Entertainment is an innovative
        entertainment company dedicated to creating unforgettable experiences.
        Our mission is to produce and distribute high quality content across
        multiple platforms, from film and television to live events and digital
        media. We bring together creative visionaries, storytellers, and
        industry professionals to deliver entertainment that resonates with
        audiences worldwide.
      </Typography>
      <Grid
        container
        spacing={isMobile ? 8 : 4}
        justifyContent="center"
        marginTop="50px"
      >
        {boxItems.map((item, i) => {
          return (
            <ServicesBox
              title={item.title}
              description={item.description}
              detailedDescription={item.detailedDescription}
              IconComponent={item.IconComponent}
              key={i}
            />
          );
        })}
      </Grid>
    </Box>
  );
};

export default OurServices;
