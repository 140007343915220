const locationsInfo = [
  {
    officeType: "Head Office",
    address:
      "88, Caval Cross Lane No-2, 1st Floor, Dadiseth Agiyari Lane, Kalbadevi Road, Mumbai, 400002",
    contact: "+91 8789371027",
  },
];

const contactUsInfo = {
  CONTACT_US_NAME: "Vinit Agarwal",
  CONTACT_US_PHONE: "+91 8789371027",
  CONTACT_US_EMAIL: "vinit@theblueinternational.com",
};

const footerInfo = {
  FOOTER_ADDRESS: locationsInfo[0].address,
  FOOTER_EMAIL: "support@theblueinternational.com",
  FOOTER_PHONE: locationsInfo[0].contact,
  FOOTER_FB: "#",
  FOOTER_INSTA: "#",
  FOOTER_TWITTER: "#",
  FOOTER_LINKEDIN: "#",
};

export { locationsInfo, contactUsInfo, footerInfo };
